<template>
  <div style="display: flex; ">
    <el-select
      :disabled="!$canAndRulesPass(row, permission)"
      v-model="row.info[evaluateKey]"
      placeholder="Выберите оценку"
      :clearable="clearable"
      @change="updateEvaluation(row.info[evaluateKey])"
    >
      <el-option
        v-for="entityEvaluation in entityEvaluations"
        :key="entityEvaluation.id"
        :label="entityEvaluation.name"
        :value="entityEvaluation.id"
      >
      </el-option>
    </el-select>
    <el-popover
      placement="top"
      width="400"
      trigger="click"
    >
      <el-button
        slot="reference"
        class="table-action-button open-desc-button"
        type="text"
        round
        size="mini"
        icon="fas fa-info-circle"
      ></el-button>
      <div style="white-space: pre-line">{{ getDescription(row.info[evaluateKey]) }}</div>
    </el-popover>
  </div>
</template>

<script>
import requestSender from "@/api/base/requestSender";

export default {
  name: "total-evaluate-select-column",
  props: {
    permission: {
      type: String,
      required: true,
    },
    entityEvaluations: {
      type: Array,
      required: true,
    },
    evaluateKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getDescription(evaluation_id) {
      const evaluation = this.entityEvaluations.find(e => e.id === evaluation_id);
      return evaluation
          ? evaluation.description ? evaluation.description : 'Нет описания'
          : 'Оценка не выбрана';
    },
    updateEvaluation(evaluate) {
      requestSender('post', 'card/total-evaluate-competence', {
        card_id: this.row.id,
        evaluation: this.evaluateKey,
        evaluation_id: evaluate,
      })
        .then(() => {
          this.$notify.success({
            title: 'Оценено',
            message: 'Оценка успешно выставлена',
          });
        })
    }
  }
}
</script>

<style scoped></style>
